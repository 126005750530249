<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <card-box ref="cardBox" :boxTitle="'图册信息'" :action="action" :params="queryParams" :reloadData.sync="reloadData">
        <template #title-right>
          <div class="tool-bar-div">
            <input class="default-bg-color" type="button" value="+ 添加图片" @click="onShowAddImg">
          </div>
        </template>
        <template #card-body="props">
          <div class="img-div cursor-el" @click="onClickImg(props.item)">
            <img :src="`${$imgBaseURL}/${props.item.img}`" alt=""/>
          </div>
        </template>
      </card-box>
    </div>
  </div>
</template>

<script>
import CardBox from "../../components/CardBox";
import UploadImgLayer from "../layer/UploadImgLayer"
import { getImgItems } from "../../common/api";

export default {
  components: { CardBox },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    onSelectedImg: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      action: getImgItems,
      reloadData: false,
      queryParams: {
        pageSize: 20
      }
    }
  },
  methods: {
    onReloadData() {
      this.reloadData = true;
    },
    onClickImg(item) {
      this.onSelectedImg(item);
      this.$layer.close(this.layerid);
    },
    onShowAddImg() {
      this.$layer.iframe({
        content: {
          content: UploadImgLayer,
          parent: this,
          data: {
            refreshData: this.onReloadData
          }
        },
        area: ["400px", "300px"],
        title: "上传图片"
      });
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    margin-top: 5px;
    .tool-bar-div {
      height: 100%;
      display: flex;
      input {
        color: white;
        font-size: 14px;
        line-height: 1.5;
        padding: 4px 16px;
        border: none;
        border-radius: 3px;
        margin: 0 5px;
      }
    }
    .img-div {
      width: 130px;
      height: 80px;
      border-radius: 3px;
      background-color: #dcdcdc;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        align-items: center;
      }
    }
  }
}
</style>