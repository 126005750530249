import { render, staticRenderFns } from "./VideoFeedbackLayer.vue?vue&type=template&id=f5921574&scoped=true&"
import script from "./VideoFeedbackLayer.vue?vue&type=script&lang=js&"
export * from "./VideoFeedbackLayer.vue?vue&type=script&lang=js&"
import style0 from "./VideoFeedbackLayer.vue?vue&type=style&index=0&id=f5921574&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5921574",
  null
  
)

export default component.exports