<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <div class="row-div">
            <div class="item-name-div img-name-div">图片</div>
            <div class="banner-img-div" v-show="!isShowAddImg">
              <img class="del-img" src="../../assets/del.png" alt="" @click="onDelImg" />
              <img ref="img" src="" alt="">
            </div>
            <div v-show="isShowAddImg">
              <label class="upload-img-label" @change="onAddImg($event)">
                <img class="cursor-el" src="../../assets/picture.png" alt="" />
                <input class="cursor-el" type="file" />
              </label>
            </div>
          </div>
        </div>
        <div class="item-div">
          <validation-provider rules="required|minmax:1,20" v-slot="{ errors }" name="bannerName">
            <div class="row-div">
              <div class="item-name-div">名称</div>
              <div class="item-control-div">
                <label><input type="text" v-model="bannerInfo.name" placeholder="请输入名称"></label>
              </div>
            </div>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <div class="row-div">
            <div class="item-name-div">展示平台</div>
            <div class="checkbox-div">
              <label><input v-model="bannerInfo.showSite[0].type" type="checkbox" @change="onTypeChanged(0)"> {{ bannerInfo.showSite[0].name }}</label>
              <label><input v-model="bannerInfo.showSite[1].type" type="checkbox" @change="onTypeChanged(1)"> {{ bannerInfo.showSite[1].name }}</label>
              <label><input v-model="bannerInfo.showSite[2].type" type="checkbox" @change="onTypeChanged(2)"> {{ bannerInfo.showSite[2].name }}</label>
            </div>
          </div>
          <div class="tips-div"></div>
        </div>
        <template v-for="item in bannerInfo.showSite">
          <template v-if="item.type">
            <div class="item-div">
              <div class="row-div">
                <div class="item-name-div">展示位置({{item.name}})</div>
                <div>
                  <div class="checkbox-div">
                    <div>
                      <label><input v-model="item.site[0]" type="checkbox"> 启动页</label>
                      <label><input v-model="item.site[1]" type="checkbox"> 首页</label>
                      <label><input v-model="item.site[2]" type="checkbox"> 分类</label>
                      <label><input v-model="item.site[3]" type="checkbox"> 二手淘宝</label>
                      <label><input v-model="item.site[4]" type="checkbox"> 朋友圈</label>
                      <label><input v-model="item.site[5]" type="checkbox"> 企业招聘</label>
                      <label><input v-model="item.site[6]" type="checkbox"> 租赁公司</label>
                      <label><input v-model="item.site[7]" type="checkbox"> 施工企业</label>
                      <label><input v-model="item.site[8]" type="checkbox"> 传统风俗</label>
                      <label><input v-model="item.site[9]" type="checkbox"> 金融服务</label>
                    </div>
                    <div>
                      <label><input v-model="item.site[10]" type="checkbox"> 品牌宣传</label>
                      <label><input v-model="item.site[11]" type="checkbox"> 行业资讯</label>
                      <label><input v-model="item.site[12]" type="checkbox"> 推广达人</label>
                      <label><input v-model="item.site[13]" type="checkbox"> 维修案例</label>
                      <label><input v-model="item.site[14]" type="checkbox"> 基础视频</label>
                      <label><input v-model="item.site[15]" type="checkbox"> 属具安装</label>
                      <label><input v-model="item.site[16]" type="checkbox"> 案例视频</label>
                      <label><input v-model="item.site[17]" type="checkbox"> 专题培训</label>
                      <label><input v-model="item.site[18]" type="checkbox"> 保险服务</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tips-div"></div>
            </div>
          </template>
        </template>
        <div class="item-div">
          <div class="row-div">
            <div class="item-name-div">投放区域</div>
            <div>
              <label>
                <select v-model="bannerInfo.province">
                  <option value="">全国</option>
                  <option v-for="item in provinces" :value="item.id">{{item.name}}</option>
                </select>
              </label>
            </div>
          </div>
          <div class="tips-div"></div>
        </div>
        <div class="item-div">
          <validation-provider rules="required" v-slot="{ errors }" name="startDate">
            <div class="row-div">
              <div class="item-name-div">开始时间</div>
              <div>
                <date-picker v-model="bannerInfo.startDate" value-type="format" :editable="false" placeholder="请选择开始时间"/>
              </div>
            </div>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <validation-provider rules="required|numeric" v-slot="{ errors }" name="displayDays">
            <div class="row-div">
              <div class="item-name-div">展示天数</div>
              <div>
                <label>
                  <input v-model="bannerInfo.days" type="text" placeholder="请输入展示天数">
                </label>
              </div>
            </div>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <div class="row-div">
            <div class="item-name-div">置顶状态</div>
            <div>
              <label><input type="checkbox" v-model="bannerInfo.index"></label>
            </div>
          </div>
        </div>
        <div class="operate-div">
          <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
          <div class="text-c">
            <input type="button" value="取消" @click="onCancel">
            <input class="save-input" type="button" value="保存" @click="onSave">
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';
import { getProvinces, getBannerDetails, postAddBanner, postEditBanner } from "@/common/api";

export default {
  components: { DatePicker },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    bannerId: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      provinces: [],
      bannerInfo: {
        name: "",
        province: "",
        startDate: "",
        days: "",
        index: false,
        showSite: [
          {id: 1, name: "用户端", type: false, site: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]},
          {id: 2, name: "商家端", type: false, site: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]},
          {id: 3, name: "服务端", type: false, site: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]}
        ]
      },
      isShowAddImg: false,
      bannerImg: null,
      tipMsg: ""
    }
  },
  created() {
    this.doGetProvinces();
    if(this.bannerId.length > 0){
      this.initData();
    }else{
      this.isShowAddImg = true;
    }
  },
  methods: {
    initData() {
      getBannerDetails({ id: this.bannerId })
        .then(data => {
          this.bannerInfo.name = data.name;
          this.bannerInfo.startDate = data.startDate;
          this.bannerInfo.days = data.days;
          this.bannerInfo.index = data.index === 1;
          this.bannerInfo.img = data.img;
          if(Object.prototype.hasOwnProperty.call(data, "province")){
            this.bannerInfo.province = data.province;
          }else{
            this.bannerInfo.province = "";
          }

          if(Array.isArray(data.site)){
            data.site.forEach(item => {
              this.bannerInfo.showSite[item.type - 1].type = true
              if(Array.isArray(item.sites)){
                item.sites.forEach(site => {
                  if(site === 99){
                    this.bannerInfo.showSite[item.type - 1].site[0] = true;
                  }else{
                    this.bannerInfo.showSite[item.type - 1].site[site] = true;
                  }
                })
              }
            });
          }

          this.$refs.img.src = `${this.$imgBaseURL}/${data.img}`;
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询Banner信息失败");
          }
          this.$layer.close(this.layerid);
        });
    },
    doGetProvinces() {
      getProvinces()
        .then(data => {
          this.provinces = data;
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询省份信息失败，请稍后重试");
          }
          this.$layer.close(this.layerid);
        });
    },
    onTypeChanged(type) {
      this.bannerInfo.showSite[type].site[0] = false;
      this.bannerInfo.showSite[type].site[1] = false;
      this.bannerInfo.showSite[type].site[2] = false;
      this.bannerInfo.showSite[type].site[3] = false;
      this.bannerInfo.showSite[type].site[4] = false;
      this.bannerInfo.showSite[type].site[5] = false;
      this.bannerInfo.showSite[type].site[6] = false;
      this.bannerInfo.showSite[type].site[7] = false;
      this.bannerInfo.showSite[type].site[8] = false;
      this.bannerInfo.showSite[type].site[9] = false;
      this.bannerInfo.showSite[type].site[10] = false;
      this.bannerInfo.showSite[type].site[11] = false;
      this.bannerInfo.showSite[type].site[12] = false;
      this.bannerInfo.showSite[type].site[13] = false;
      this.bannerInfo.showSite[type].site[14] = false;
      this.bannerInfo.showSite[type].site[15] = false;
      this.bannerInfo.showSite[type].site[16] = false;
      this.bannerInfo.showSite[type].site[17] = false;
      this.bannerInfo.showSite[type].site[18] = false;
    },
    onAddImg(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.$refs.img.src = reader.result;
        that.bannerImg = img;
        that.isShowAddImg = false;
        event.target.value = null;
      };
      reader.onerror = function() {
        this.onDelImg();
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onDelImg() {
      this.bannerInfo.img = null;
      this.bannerImg = null;
      this.$refs.img.src = "";
      this.isShowAddImg = true;
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      if(this.bannerId.length > 0){
        if(this.bannerInfo.img == null && this.bannerImg == null){
          this.setTipsMsg("请选择图片");
          return;
        }
      }else{
        if(this.bannerImg == null){
          this.setTipsMsg("请选择图片");
          return;
        }
      }

      this.$refs.form.validate().then(res => {
        if(res){
          if(this.bannerId.length > 0){
            this.editBanner();
          }else{
            this.addBanner();
          }
        }
      })
    },
    addBanner() {
      let formData = new FormData();
      formData.append("name", this.bannerInfo.name);
      formData.append("province", this.bannerInfo.province);
      formData.append("startDate", this.bannerInfo.startDate);
      formData.append("days", this.bannerInfo.days);
      formData.append("index", this.bannerInfo.index ? 1 : 0);
      formData.append("file", this.bannerImg)

      let i = 0;
      let j;
      this.bannerInfo.showSite.forEach(item => {
        if(item.type){
          j = 0;
          item.site.forEach((v, k) => {
            if(v){
              if(k === 0){
                formData.append(`display[${i}].sites[${j}]`, 99);
              }else{
                formData.append(`display[${i}].sites[${j}]`, k);
              }
              ++j;
            }
          });

          if(j > 0){
            formData.append(`display[${i}].type`, item.id);
            ++i;
          }
        }
      });

      postAddBanner(formData)
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    editBanner() {
      let formData = new FormData();
      formData.append("id", this.bannerId);
      formData.append("name", this.bannerInfo.name);
      formData.append("province", this.bannerInfo.province);
      formData.append("startDate", this.bannerInfo.startDate);
      formData.append("days", this.bannerInfo.days);
      formData.append("index", this.bannerInfo.index ? 1 : 0);
      if(this.bannerImg != null){
        formData.append("file", this.bannerImg)
      }

      let i = 0;
      let j;
      this.bannerInfo.showSite.forEach(item => {
        if(item.type){
          j = 0;
          item.site.forEach((v, k) => {
            if(v){
              if(k === 0){
                formData.append(`display[${i}].sites[${j}]`, 99);
              }else{
                formData.append(`display[${i}].sites[${j}]`, k);
              }
              ++j;
            }
          });

          if(j > 0){
            formData.append(`display[${i}].type`, item.id);
            ++i;
          }
        }
      });

      postEditBanner(formData)
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div:first-child {
      margin-bottom: 20px;
    }
    .item-div {
      margin-top: 5px;
      .row-div {
        display: flex;
        align-items: center;
        .item-name-div {
          width: 60px;
          text-align: right;
          margin-right: 15px;
        }
        .checkbox-div {
          label {
            margin-right: 6px;
          }
        }
        input[type="checkbox"] {
          width: 20px;
          height: 20px;
          border: 1px solid #dcdcdc;
          vertical-align: middle;
        }
        .banner-img-div {
          width: calc(100% - 75px);
          height: 160px;
          position: relative;
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
          .del-img {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 3px;
            right: 3px;
          }
        }
      }
      .tips-div {
        width: calc(100% - 75px);
        height: 25px;
        margin-left: 80px;
      }
      input[type="text"] {
        width: 358px;
        height: 33px;
        padding: 0 5px;
        border: 1px solid #dcdcdc;
      }
      select {
        width: 220px;
        height: 33px;
        border: 1px solid #dcdcdc;
      }
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>