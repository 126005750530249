<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <div class="item-div">
        <div class="item-name-div">讲师名称：</div>
        <div class="item-value-div">{{ feedbackInfo.lecturerName }}</div>
      </div>
      <div class="item-div">
        <div class="item-name-div">课程标题：</div>
        <div class="item-value-div">{{ feedbackInfo.title }}</div>
      </div>
      <div class="item-div">
        <div class="item-name-div">用户昵称：</div>
        <div class="item-value-div">{{ feedbackInfo.userNickname }}</div>
      </div>
      <div class="item-div">
        <div class="item-name-div">反馈类型：</div>
        <div class="item-value-div">{{ feedbackType }}</div>
      </div>
      <div class="item-div">
        <div class="item-name-div">反馈时间：</div>
        <div class="item-value-div">{{ feedbackInfo.createTime }}</div>
      </div>
      <div class="item-div">
        <div class="item-name-div">反馈内容：</div>
        <div class="item-value-div">
          <div class="msg-div">{{ feedbackInfo.description }}</div>
        </div>
      </div>
      <div class="operate-div text-c">
        <input type="button" value="取消" @click="onCancel">
      </div>
    </div>
  </div>
</template>

<script>
import { postLecturerVideoCommentReply } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    feedbackInfo: {
      type: Object,
      default: () => {}
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    feedbackType() {
      switch (this.feedbackInfo.type) {
        case 0: return "建议";
        case 1: return "内容有误";
        case 2: return "没听懂";
        default: return "其他";
      }
    }
  },
  methods: {
    onCancel() {
      this.$layer.close(this.layerid);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .item-name-div {
        width: 100px;
        text-align: right;
      }
      .item-value-div {
        .msg-div {
          width: 322px;
          height: 92px;
          padding: 3px;
          border: 1px solid #dcdcdc;
          border-radius: 3px;
          overflow-y: auto;
        }
        textarea {
          width: 322px;
          height: 92px;
          resize: none;
          padding: 3px;
          border: 1px solid #dcdcdc;
          border-radius: 3px;
        }
      }
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
    }
  }
}
</style>