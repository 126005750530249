<template>
  <div class="layer-page-div">
    <div class="preview-img-div">
      <label v-show="img == null">请选择图片</label>
      <img v-show="img != null" ref="img" src="" alt="">
      <input class="cursor-el" type="file" @change="onAddImg($event)" />
    </div>
    <div class="operate-btn-div">
      <input type="button" value="取消" @click="onCancel">
      <input class="save-btn" type="button" value="保存" @click="onSave">
    </div>
  </div>
</template>

<script>
import { postImgAdd } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      img: null,
      tipMsg: ""
    }
  },
  methods: {
    onAddImg(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png|gif)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg、png和gif格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.$refs.img.src = reader.result;
        that.img = img;
        event.target.value = null;
      };
      reader.onerror = function() {
        this.onDelImg();
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onSave() {
      if(this.img == null){
        this.$layer.msg("请选择图片");
        return;
      }

      let formData = new FormData();
      formData.append("file", this.img)
      postImgAdd(formData)
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("上传成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("上传失败");
          }
        });
    },
    onCancel() {
      this.$layer.close(this.layerid);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div{
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .preview-img-div {
    width: 300px;
    height: 190px;
    margin: 5px auto 0 auto;
    border: 1px solid #dcdcdc;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      //z-index: -1;
    }
  }
  .operate-btn-div {
    text-align: center;
    margin-top: 10px;
    input {
      color: white;
      font-size: 14px;
      line-height: 1.5;
      padding: 4px 16px;
      border: none;
      border-radius: 3px;
      background-color: #207ab7;
    }
    .save-btn {
      margin-left: 10px;
    }
  }
}
</style>