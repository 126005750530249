<template>
  <div class="card-box-content-div">
    <div class="card-title-div">
      <div class="left-div">
        <div class="data-title-div">{{ boxTitle }}</div>
        <div class="data-total-div">(共 {{ dataTotal }} 条记录)</div>
        <div>
          <img class="cursor-el" src="../assets/refresh.png" height="18" width="19" title="刷新" alt="刷新" @click="fetchData(1)" />
        </div>
      </div>
      <div>
        <slot name="title-right" />
      </div>
    </div>
    <div class="text-c" v-if="isLoading">数据加载中...</div>
    <div class="text-c" v-else-if="items.length === 0">没有数据</div>
    <div class="card-div" v-else>
      <slot name="card-body" v-for="(item, index) in items" :item="item" :index="index" />
    </div>
    <div class="box-footer-div">
      <label class="page-total-label">
        每页显示
        <select v-model="pageSize">
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        条
      </label>
      <img class="turn-page-image cursor-el" :src="getArrowLeft()" @click="onBeforePage" alt="" />
      <input v-if="dataPageTotal > 0" :class="{ 'page-num-btn': true, 'current-page-num': currentPageNum === 1 }" type="button" value="1" @click="onToPage(1)" />
      <label v-if="currentPageNum > 4 && dataPageTotal > 7">......</label>
      <input v-for="index in getPageNumBtn()" :key="index" :class="{ 'page-num-btn': true, 'current-page-num': currentPageNum === index + pageNumOffsets }" type="button" :value="index + pageNumOffsets" @click="onToPage(index + pageNumOffsets)" />
      <label v-if="dataPageTotal - currentPageNum > 3 && dataPageTotal > 7">......</label>
      <input v-if="dataPageTotal > 1" :class="{ 'page-num-btn': true, 'current-page-num': currentPageNum === dataPageTotal }" type="button" :value="dataPageTotal" @click="onToPage(dataPageTotal)" />
      <img class="turn-page-image cursor-el" :src="getArrowRight()" @click="onNextPage" alt="" />
      <label class="jump-txt-label">跳转到第</label>
      <label>
        <input class="page-num-input" type="text" v-model="turnPageNum" />
      </label>
      <label>页</label>
      <input class="jump-page-btn" type="button" value="确定" @click="onTurnPage" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    boxTitle: {
      type: String,
      default: ""
    },
    action: {
      type: Function,
      default: () => {}
    },
    params: {
      type: Object,
      default: () => {}
    },
    reloadData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pageSize: 20,
      dataTotal: 0,
      dataPageTotal: 0,
      currentPageNum: 0,
      pageNumOffsets: 0,
      turnPageNum: "",
      items: [],
      isLoading: false
    };
  },
  created() {
    this.fetchData(1);
  },
  watch: {
    reloadData() {
      if (this.reloadData) {
        this.fetchData(1);
        this.$emit("update:reloadData", false);
      }
    },
    pageSize() {
      this.params.pageSize = this.pageSize;
    },
    params: {
      handler() {
        this.fetchData(1);
      },
      deep: true
    }
  },
  methods: {
    getArrowLeft() {
      return this.currentPageNum > 1 ? require("../assets/arrow-left-1.png") : require("../assets/arrow-left-2.png");
    },
    getArrowRight() {
      return this.currentPageNum < this.dataPageTotal ? require("../assets/arrow-right-1.png") : require("../assets/arrow-right-2.png");
    },
    fetchData(pageNum) {
      this.params.pageNum = pageNum;
      this.isLoading = true;
      this.items = [];
      this.action(this.params)
          .then(data => {
            if (data && data.list instanceof Array) {
              this.currentPageNum = pageNum;
              this.items = data.list;

              if(data.pageNum === 1){
                this.dataTotal = data.total;
                this.dataPageTotal = data.pages;
              }
            }
            this.isLoading = false;
          })
          .catch(error => {
            this.isLoading = false;
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("查询数据失败");
            }
          });
    },
    getPageNumBtn() {
      if (this.dataPageTotal < 8) {
        let total = this.dataPageTotal - 2;
        if (total > 0) {
          this.pageNumOffsets = 1;
          return total;
        } else {
          this.pageNumOffsets = 0;
          return 0;
        }
      }

      if (this.currentPageNum < 5) {
        this.pageNumOffsets = 1;
        return 4;
      } else {
        if (this.dataPageTotal - this.currentPageNum > 3) {
          this.pageNumOffsets = this.currentPageNum - 2;
          return 3;
        } else {
          this.pageNumOffsets = this.dataPageTotal - 5;
          return 4;
        }
      }
    },
    onBeforePage() {
      if (this.currentPageNum > 1) {
        this.fetchData(this.currentPageNum - 1);
      }
    },
    onNextPage() {
      if (this.currentPageNum < this.dataPageTotal) {
        this.fetchData(this.currentPageNum + 1);
      }
    },
    onTurnPage() {
      if (this.currentPageNum !== parseInt(this.turnPageNum) && this.turnPageNum >= 1 && this.turnPageNum <= this.dataPageTotal) {
        this.fetchData(parseInt(this.turnPageNum));
      }
    },
    onToPage(num) {
      if (this.currentPageNum !== num) {
        this.fetchData(num);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.card-box-content-div {
  width: 100%;
  .card-title-div {
    height: 35px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .left-div {
      line-height: 35px;
      display: flex;
      .data-title-div {
        font-size: 17px;
        font-weight: bold;
      }
      .data-total-div {
        margin-left: 10px;
        margin-right: 20px;
      }
      img {
        vertical-align: middle;
      }
    }
  }
  .card-div {
    width: calc(100% - 12px);
    border: 1px solid #e5e5e5;
    padding: 10px 5px;
    display: flex;
    flex-wrap: wrap;
  }
  .box-footer-div {
    margin-top: 2px;
    padding: 40px 5px 30px 5px;
    text-align: right;
    font-size: 0;
    input,
    label,
    img {
      font-size: 12px;
      vertical-align: middle;
    }
    .page-total-label {
      margin-right: 15px;
      select {
        width: 60px;
        height: 25px;
        border: 1px solid #e5e5e5;
      }
    }
    .turn-page-image {
      width: 9px;
      height: 17px;
      margin: 0 5px;
    }
    .page-num-btn {
      width: 40px;
      height: 25px;
      border: 1px solid #e5e5e5;
      background: #eeeeee;
    }
    .current-page-num {
      background: #ffffff;
      color: #4676c8;
    }
    .jump-txt-label {
      margin-left: 10px;
    }
    .page-num-input {
      width: 38px;
      height: 23px;
      text-align: center;
      border: 1px solid #e5e5e5;
      margin: 0 5px;
    }
    .jump-page-btn {
      width: 55px;
      height: 25px;
      background: #eeeeee;
      border: 1px solid #dcdcdc;
      margin-left: 15px;
    }
  }
}
</style>