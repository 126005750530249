<template>
  <div class="tinymce-editor">
    <editor id="tinymce" v-model="myValue" :init="init" @onClick="onClick"></editor>
  </div>
</template>

<script>
import tinymce from "tinymce/tinymce"
import Editor from "@tinymce/tinymce-vue"
import "tinymce/skins/content/default/content.css"
import "tinymce/themes/silver/theme.min.js"
import "tinymce/icons/default/icons"
import "tinymce/plugins/hr"
import "tinymce/plugins/lists"
import "tinymce/plugins/table"
import "tinymce/plugins/wordcount"
import "tinymce/plugins/preview"
import "tinymce/plugins/fullscreen"
import AtlasLayer from "../pages/layer/AtlasLayer";

export default {
  name: "Tinymce",
  components: { Editor },
  props: {
    value: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: [String, Array],
      default: "hr lists table wordcount preview fullscreen"
    },
    toolbar: {
      type: [String, Array],
      default: "undo redo | formatselect | bold italic underline | forecolor backcolor table | hr numlist bullist | alignleft aligncenter alignright alignjustify | removeformat | fullscreen preview | atlasBtn"
    },
    height:{
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      myEditor: null,
      myValue: this.value,
      init: {
        language_url: "/tinymce/langs/zh_CN.js",
        language: "zh_CN",
        skin_url: "/tinymce/skins/ui/oxide",
        convert_urls: false,
        height: this.height,
        plugins: this.plugins,
        toolbar: this.toolbar,

        statusbar: false, // 底部的状态栏
        // menubar: "file edit insert view format table tools", // （1级菜单）最上方的菜单 help 帮助
        branding: false, // （隐藏右下角技术支持）水印“Powered by TinyMCE”
        paste_data_images: false, // 设置为“true”将允许粘贴图像，而将其设置为“false”将不允许粘贴图像
        setup: editor => {
          let _this = this;
          _this.myEditor = editor;
          editor.ui.registry.addButton("atlasBtn", {
            text: "图册",
            onAction: function (_) {
              _this.$layer.iframe({
                content: {
                  content: AtlasLayer,
                  parent: _this,
                  data: {
                    onSelectedImg: _this.addImg
                  }
                },
                area: ["713px", "590px"],
                title: "图册"
              });
            }
          });
        }
      }
    };
  },
  methods: {
    onClick(e) {
      this.$emit("onClick", e, tinymce);
    },
    clear() {
      this.myValue = "";
    },
    addImg(item) {
      this.myEditor.insertContent(`<img src="${this.$imgBaseURL}/${item.img}" alt="">`);
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue;
    },
    myValue(newValue) {
      this.$emit("input", newValue);
    }
  }
}
</script>
